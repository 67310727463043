import { Button, Container, Grid, TextField } from '@material-ui/core';
import React, { useContext, useState } from 'react'
import styles from '../assets/scss/login.module.scss'
import GridRow from './_grid-row';
import UserContext from '../providers/user-provider'
import { navigate } from "gatsby"

export default function Login() {

    const { user, setUser, handleLogin, redirectUrl, errorMsg } = useContext(UserContext);

    const [loginFormData, setLoginFormData] = useState({
        personal: {
            email: '',
            password: ''
        }
    })

    const [invalidForm, setInvalidForm] = useState(true);
    const personal = loginFormData.personal;

    const [formDirtyFields, setFormDirtyFields] = useState({
        email: false,
        password: false
    });

    const helperText = {
        email: 'Please enter a valid email.',
        password: 'Password must be at least 8 characters.'
    }
    console.log('ash err login', errorMsg)


    function handleTextField(e) {
        const name = e.target.name;
        const value = e.target.value;
        const type = e.target.type;

        if (
            value === undefined ||
            value == '' ||
            (type == 'email' && !isValidEmail(value)) ||
            (type == 'password' && !isValidPassword(value))
        ) {
            setInvalidForm(true);
            setFormDirtyFields({ ...formDirtyFields, [name]: true });

        } else {
            setInvalidForm(false);
            setFormDirtyFields({ ...formDirtyFields, [name]: false });
            personal[name] = value;

        }
    }

    function isValidEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    function isValidPassword(pass) {
        // if (pass.length < 5) {
        //     return false;
        // }

        return true;
    }

    function submit(e) {
        e.preventDefault();

        let isFormValid = false;
        for (const key in formDirtyFields) {
            if (Object.hasOwnProperty.call(formDirtyFields, key)) {
                const fieldValidation = formDirtyFields[key];
                if (fieldValidation || personal[key] == undefined || personal[key] == '') {
                    isFormValid = false;
                    setFormDirtyFields({ ...formDirtyFields, [key]: true });
                    break;
                }
            }

            isFormValid = true;
        }

        if (isFormValid) {
            handleLogin(personal);
            console.log('ashhhh', user)
        }


        return false;
    }


    if (user && typeof window !== 'undefined') {
        navigate(redirectUrl ? redirectUrl : `/profile`)
      }
    return (
        <React.Fragment>
            <div className={[styles.wrapper, "login"].join(" ")}>
                <Container maxWidth="xl">
                    <GridRow>
                        <Grid container spacing={3} direction="row-reverse" justify="center">

                            <Grid item xs={12} lg={8}>
                                <div className={styles.registerHeader}>
                                    <h1 className="h1 orange">
                                        Sign In
                                    </h1>
                                    <p className="text-01">
                                        Welcome to Beach Monkeyz, let’s get stronger than yesterday. We have an event coming up and we’d love to have you on our team.
                                    </p>
                                </div>
                                <form noValidate autoComplete="off" onSubmit={submit}>

                                    <div className={styles.formField}>
                                        <TextField
                                            label="E-mail"
                                            type="email"
                                            name="email"
                                            variant="outlined"
                                            classes={{ root: styles.input }}
                                            onChange={handleTextField}
                                            error={formDirtyFields.email}
                                            helperText={formDirtyFields.email ? helperText.email : ''}
                                            required />
                                    </div>
                                    <div className={styles.formField}>
                                        <TextField
                                            label="Password"
                                            type="password"
                                            name="password"
                                            variant="outlined"
                                            classes={{ root: styles.input }}
                                            onChange={handleTextField}
                                            error={formDirtyFields.password}
                                            helperText={formDirtyFields.password ? helperText.password : ''}
                                            required
                                        />
                                    </div>
                                    {errorMsg? <p className="error">{errorMsg}</p> : ""}

                                    <div className={styles.formField}>
                                        <Button disabled={invalidForm} variant="contained" color="primary" disableElevation type="submit" classes={{ root: styles.btn }}>Sign In</Button>
                                    </div>
                                </form>

                            </Grid>
                        </Grid>
                    </GridRow>
                </Container>
            </div>

        </React.Fragment>
    )
}
