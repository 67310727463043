import * as React from "react"

import Layout from "../components/layout"
import Login from "../components/login"
import PrivateRoute from "../components/privateRoute"
import { Router } from "@reach/router"
import Seo from "../components/seo"


const LoginPage = () => (
  <Layout pageName="Sign In">
    <Seo title="Sign In" />
    <Login />
  </Layout>
)

export default LoginPage
